import { cubehelix } from 'd3-color';

export default function cubehelixColor(c) {
  // These points are taken from mbostock's original cubehelix post
  // linearly interpolate between them in the cubehelix color space
  const colors = [
    { index: 0.0, hsl: [-100, 0.75, 0.35]},
    { index: 0.5, hsl: [  80, 1.50, 0.80]},
    { index: 1.0, hsl: [ 260, 0.75, 0.35]},
  ];

  function lerp(v0, v1, t) {
    return (1 - t) * v0 + t * v1;
  }

  let color;

  for (let i = 0; i < colors.length - 1; i++) {
    const c1 = colors[i];
    const c2 = colors[i+1];
    if (c1.index <= c && c < c2.index) {
      const t = (c - c1.index) / (c2.index - c1.index);
      color = [
        lerp(c1.hsl[0], c2.hsl[0], t),
        lerp(c1.hsl[1], c2.hsl[1], t),
        lerp(c1.hsl[2], c2.hsl[2], t),
      ];
      break;
    }
  }

  return cubehelix(color[0],color[1],color[2]);
}
