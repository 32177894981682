import './App.css';

import { useState, useEffect } from 'react';
import ajax from './ajax';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";

import Layout from './Layout';
import MainText from './MainText';
import HealthInsuranceExplainer from './HealthInsuranceExplainer';
import Posts from './Posts';
import FlexboxFriend from './FlexboxFriend';

function App() {
  return (<Router>
    <Switch>
      <Route path="/lab/health_insurance_explainer">
        <HealthInsuranceExplainer />
      </Route>
      <Route path="/posts">
        <Layout><Posts /></Layout>
      </Route>
      <Route path="/flexbox_friend">
        <FlexboxFriend />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  </Router>);
}

function Home() {
  const [counter, setCounter] = useState(null);

  function getCounter() {
    ajax("/counter")
      .then(data => setCounter(data.counter));
  }

  function handleClick() {
    ajax("/counter", { method: "POST" })
      .then(data => setCounter(data.counter));
  }

  useEffect(() => {
    getCounter();
  }, []);

  if ((/health_insurance_explainer/).test(window.location.search)) {
    return <Redirect to="/lab/health_insurance_explainer" />
  }

  return (
    <Layout>
      <div className='container__col container'>
        <article className='container__col'>
          <MainText />
        </article>
        <div className='sidebar container__col'>
          <p>
            <Link to="/posts">Useful Code Snippets</Link>
          </p>
          You can tell this isn't a static site because of this stateful counter that persists across page loads:
          { counter != null && <div className='Counter'>
            <button onClick={ handleClick }>This button</button> has been
            clicked <span className='Counter__count'>{ counter }</span> times
            since the server was restarted.
          </div> }
          <p>
            Testing rollbacks v1!
          </p>
        </div>
      </div>
    </Layout>
  );
}

export default App;
