import './Posts.css';

import { useState, useEffect } from 'react';

import { Switch, Route, Link, useParams, useRouteMatch } from "react-router-dom";

import ajax from './ajax';

export default function Posts() {
  const { path } = useRouteMatch();

  return <div className='Posts container container__col'>
    <Switch>
      <Route path={ `${path}/:postId` }>
        <PostPage />
      </Route>
      <Route path={ `${path}/` }>
        <AllPosts />
      </Route>
    </Switch>
  </div>;
}

function PostPage() {
  const { postId } = useParams();
  return <Post postId={postId} />;
}

function Post({ postId }) {
  const [loading, setLoading] = useState(true);
  const [post, setPost] = useState(null);

  function getPost() {
    ajax(`/posts/${postId}`)
      .then(response => {
        setPost(response.post);
        setLoading(false);
      });
  }

  useEffect(getPost, [postId]);

  return <article className='Post container__col'>
    { loading ?
      <h1>LOADING</h1> :
      <div dangerouslySetInnerHTML={{__html: post}} />
    }
  </article>;
}

function AllPosts() {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState(null);

  function getPosts() {
    ajax('/posts/api')
      .then(response => {
        setPosts(response.posts);
        setLoading(false);
      });
  }

  useEffect(getPosts, []);

  return <div className='Posts container container__col'>
    <h1 className='container__col'>All posts!</h1>
    <p className='Posts__explanation container__col'>These are mostly snippets of very specific things I couldn't easily find anywhere else on the internet, so here's hoping google indexes them.</p>
    { loading ?
      <h1>LOADING</h1> :
      posts.map(p => <div>
        <h3 className='Posts__permalink container__col'>
          <Link to={ `/posts/${p.name}` }>Posted { (new Date(p.lastModified * 1000)).toDateString() }:</Link>
        </h3>
        <Post postId={p.name} />
      </div>)
    }
  </div>;
}
