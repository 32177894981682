export default function ajax(path, options = {}) {
  options = {
    ...options,
    headers: {
      ...(options.headers || {}),
      'Accept': 'application/json',
    }
  };

  // TODO: handle errors here.
  return baseAjax(path, options)
    .then(response => response.json())
}

export function baseAjax(path, options = {}) {
  const base = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ?
    'http://localhost:8080' :
    '';
  const url = base + path

  return fetch(url, options)
    .catch(error => {
      console.log("Error:", error)
      // oh, this should re-raise an error, not a plain return
      return error;
    })
}
