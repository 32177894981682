import { Fragment } from 'react';

export default function MainText() {
  return (<Fragment>
    <h2>I'm Jesse Youngmann.</h2>
    <p>I'm a full-stack web developer.</p>
    <p>I have experience in Rails, React, Postgres, Chef, Ansible, and a bunch of other web development tools.</p>
    <p>I've really enjoyed using Rust for personal projects.</p>
    <p>You can find my <a href='https://linkedin.com/in/jesse-youngmann'>resume at linkedin</a> and <a href='https://github.com/jesseyoungmann'>some public code at github</a>.</p>

    <h2>There's not much here.</h2>
    <p>This site is mostly for testing webdev tools.</p>
    <p>I made it with React for the frontend, and a Rust backend in Actix, which is great and entirely overkill for a personal site.</p>
    <p>I automated provisioning and compiling and deploying it to a DigitalOcean droplet with Docker, Webpack, and Ansible. (I'm not actually deploying a docker container, just cross compiling it for linux with docker.)</p>
    <p>It's being served by nginx and Actix.</p>
    <p>It's got HTTPS using Let's Encrypt and certbot.</p>
    <p>Maybe there'll be more stuff here later. Until then, go read some of my favorite things:</p>
    <h2>Some of my favorite things:</h2>
    <p><a href='https://gunnerkrigg.com'>Gunnerkrigg Court</a></p>
    <p><a href='https://octopuspie.com'>Octopus Pie</a></p>
    <p><a href='https://paranatural.net'>Paranatural</a></p>
    <p>
      Some great indie games: <a href='http://fezgame.com'>Fez
      </a>, <a href='https://subsetgames.com'>Faster Than Light
      </a>, <a href='https://www.megacrit.com'>Slay the Spire
      </a>, <a href='https://hollowknight.com'>Hollow Knight
      </a>, <a href='https://www.supergiantgames.com/games/hades/'>Hades
      </a>, <a href='https://braceyourselfgames.com/crypt-of-the-necrodancer/'>Crypt of the Necrodancer
      </a>
    </p>
  </Fragment>);
}
