import './HealthInsuranceExplainer.css';

import { useState } from 'react';
import cubehelixColor from './cubehelix';

export default function HealthInsuranceExplainer() {
  //const [realShowCompanyPays, setShowCompanyPays] = useState(true);
  //const [realShowEmployeePays, setShowEmployeePays] = useState(true);
  const [selection, setSelection] = useState(null);
  const [medicalSpend, setMedicalSpend] = useState(500);

  function setSelectionAndScroll(s) {
    setSelection(s);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  const okay = selection === null;

  const showCompanyPays = okay;
  const showEmployeePays = okay || selection === 'mostYouCanPay' || selection === 'compare';
  const showTotal = okay;

  const plans = [
    {
      deductible: 5000,
      familyDeductible: 1000,
      oopm: 5000,
      familyOopm: 5000,
      coinsurance: 100,
      name: 'H50 PPO Plus HSA',
      companyPays: {
        employee: 296.80 * 12,
      },
      employeePays: {
        employee: 0.00 * 12,
      },
      copay: null,
      copaySpecialist: null,
    },

    {
      deductible: 3000,
      familyDeductible: 1000,
      oopm: 6000,
      familyOopm: 5000,
      coinsurance: 80,
      name: 'P30 PPO Plus',
      companyPays: {
        employee: 358.50 * 12,
      },
      employeePays: {
        employee: 0.00 * 12,
      },
      copay: 25,
      copaySpecialist: 50,
    },

    {
      deductible: 2000,
      familyDeductible: 4000,
      oopm: 4000,
      familyOopm: 8000,
      coinsurance: 80,
      name: 'P20 PPO Plus',
      companyPays: {
        employee: 358.50 * 12,
      },
      employeePays: {
        employee: 45.84 * 12,
      },
      copay: 25,
      copaySpecialist: 50,
    },

    {
      deductible: 500,
      familyDeductible: 1000,
      oopm: 2500,
      familyOopm: 5000,
      coinsurance: 90,
      name: 'S05 PPO Plus',
      companyPays: {
        employee: 358.50 * 12,
      },
      employeePays: {
        employee: 139.81 * 12,
      },
      copay: 25,
      copaySpecialist: 50,
    },
  ];

  const totalLabelPercentage = 0.06;

  const tier = 'employee';
  const maxCompanyPays = plans.map(p => p.companyPays[tier]).reduce((a,b) => Math.max(a,b));
  const maxWidth = plans.map(p => (showCompanyPays ? maxCompanyPays : 0) + (showEmployeePays ? p.employeePays[tier] : 0) + p.oopm)
    .reduce((a,b) => Math.max(a,b)) * (1 + totalLabelPercentage);

  function pct(v) {
    const percent = Math.round((v / maxWidth) * 10000) / 100;
    return `${percent}%`;
  }

  function money(v) {
    return `$${Math.round(v)}`;
  }

  function zeroClass(v) {
    if (Math.round(v * 100) === 0) {
      return 'plan-math__amount-zero';
    } else {
      return '';
    }
  }

  function renderPlan(plan) {
    const deductible = plan.deductible;
    const companyPaysMargin = (maxCompanyPays - plan.companyPays[tier]);
    const companyPays = plan.companyPays[tier];
    const employeePays = plan.employeePays[tier];
    const oopm = (plan.oopm - plan.deductible);

    const bars = [{
      name: "Employer Contribution",
      hide: !showCompanyPays,
      width: companyPays,
      marginLeft: companyPaysMargin,
      value: plan.companyPays[tier],
      klass: 'company-contribution',
    }, {
      name: "Employee Contribution",
      hide: !showEmployeePays,
      width: employeePays,
      value: plan.employeePays[tier],
      klass: 'employee-contribution',
    }, {
      name: "Deductible",
      width: deductible,
      value: plan.deductible,
      klass: 'deductible',
      highlight: selection === 'deductible',
    }, {
      name: 'OOPM',
      width: oopm,
      value: plan.oopm - plan.deductible,
      sub: '(+ded)',
      klass: 'oopm',
    }];

    const COST = 234;

    let examples = null;
    if (selection === 'copay' || selection === 'nonCopay' || selection === 'preventive' ) {
      const startingMargin = showCompanyPays ? maxCompanyPays : 0 + showEmployeePays ? plan.employeePays[tier] : 0;

      const isCopay = selection === 'copay';
      const isHDHP = plan.copay === null && plan.deductible === plan.oopm;

      if (selection === 'preventive') {
        examples = [{
          width: 0,
          marginLeft: startingMargin,
          amount: 0,
          note: "Preventive care is always covered.",
        }];
        if (!isHDHP) {
          examples.push({
            width: 0,
            marginLeft: plan.deductible,
            note: "Preventive care is always covered.",
            amount: 0,
          });
        }
      // TODO: check if copay is available here!
      } else if (plan.copay !== null && plan.coinsurance !== null) {
        const amount = isCopay ? plan.copay : COST;

        const secondAmount = isCopay ? plan.copay : COST * ((100 - plan.coinsurance) / 100);

        examples = [{
          width: amount,
          marginLeft: startingMargin,
          amount,
          note: isCopay ? "Just pay the copay." : "Pay full amount until deductible is reached",
        }, {
          width: secondAmount,
          marginLeft: (plan.deductible - amount),
          amount: secondAmount,
          note: isCopay ? "Just pay the copay." : `Plan pays coinsurance of ${ plan.coinsurance }%, you pay ${ 100 - plan.coinsurance }% of total cost.`,
        }, {
          width: 0,
          marginLeft: (plan.oopm - plan.deductible - secondAmount),
          amount: 0,
          note: "Sana covers all costs after oopm is reached.",
        }];
      } else if (isHDHP) {
        examples = [{
          width: COST,
          marginLeft: startingMargin,
          amount: COST,
          note: "Pay full amount until deductible is reached.",
        }, { // if deductible == oopm
          width: 0,
          marginLeft: (plan.oopm - COST),
          note: "Sana covers all costs after deductible is reached.",
          amount: 0,
        }];
      } else {
        throw new Error("Not a valid plan type");
      }
    } else if (selection === 'mostYouCanPay') {
      const startingMargin = showCompanyPays ? maxCompanyPays : 0;
      const amount = (showEmployeePays ? plan.employeePays[tier] : 0) + plan.oopm;
      examples = [{
        width: amount,
        amount,
        marginLeft: startingMargin,
        note: "The most you can pay in a year is your employee contributions plus your out of pocket max.",
        highlight: true,
      }];
    } else if (selection === 'compare') {
      let margin = showCompanyPays ? maxCompanyPays : 0;
      let total = 0;
      examples = [];

      if (plan.employeePays[tier] !== 0) {
        let amount = plan.employeePays[tier];
        examples.push({
          width: amount,
          amount,
          marginLeft: margin,
          note: "You always pay premiums.",
        });
        margin = 0;
        total = total + amount;
      }

      let amountLeft = medicalSpend;

      if (amountLeft > 0 && plan.deductible !== 0) {
        const amount = Math.min(amountLeft, plan.deductible);
        amountLeft = amountLeft - amount;

        let note = `You pay the full amount of the first ${money(amount)}`;
        if (plan.copay !== null) {
          note = note + " (Assume no copays here for simplicity.)";
        }

        examples.push({
          width: amount,
          amount,
          note,
        });
        total = total + amount;
      }

      if (amountLeft > 0 && plan.deductible !== plan.oopm) {
        const preAmount = Math.min(amountLeft, (plan.oopm - plan.deductible) / (1 - plan.coinsurance / 100));

        amountLeft = amountLeft - preAmount;

        const amount = preAmount * (1 - plan.coinsurance / 100);

        examples.push({
          width: amount,
          amount,
          note: `Coinsurance kicks in and reduces ${ money(preAmount) } by ${ plan.coinsurance }%`,
        });
        total = total + amount;
      }

      examples.push({
        isTotal: true,
        amount: total,
        sub: amountLeft > 0 ? `You pay none of the remaining ${ money(amountLeft)}.` : null,
      });

    }

    return (<div className='plan'>
      { plan.name }
      <div className='plan-math'>
        { bars.map((bar, index) => bar.hide ? null :
          <div
            className={ `plan-math__amount plan-math__${bar.klass} ${zeroClass(bar.width)} ${bar.highlight ? 'plan-math__amount--selected' : ''}` }
            style={{ width: pct(bar.width), marginLeft: pct(bar.marginLeft || 0), backgroundColor: cubehelixColor(index / bars.length) }}
          >
            <div className='plan-math__amount-label'>
              { money(bar.value) }
              { bar.sub ? <sub>{ bar.sub }</sub> : null }
              <div className='abuts'><div className='plan-math__amount-label__name'>{ bar.name }</div></div>
            </div>
          </div>
        )}
        { !showTotal ? null : <div className='plan-math__amount-label plan-math__total-amount' style={{ width: pct(totalLabelPercentage / (1 + totalLabelPercentage)) }}>
          Total:<br />{ money(plan.oopm + plan.employeePays[tier]) }
        </div> }
      </div>
      { examples === null ? null :
        <div className='plan-math plan-math--lower'>
          { examples.map((bar) => bar.isTotal ?
            <div className='plan-math__amount-label plan-math__total-amount' style={{ width: pct(totalLabelPercentage / (1 + totalLabelPercentage)) }}>
              Total you pay:<br />{ money(bar.amount) }
              { bar.sub ? <sub>{ bar.sub }</sub> : null }
            </div>
            :
            <div
              className={ `plan-math__amount plan-math__example-owed ${bar.highlight ? 'plan-math__amount--selected' : ''}` }
              style={{ width: pct(bar.width), marginLeft: pct(bar.marginLeft || 0) }}
            >
              <div className='plan-math__amount-label'>
                { money(bar.amount) }
                <div className='abuts'><div className='plan-math__amount-label__name'>{ bar.note }</div></div>
              </div>
            </div>
          ) }
        </div>
      }
    </div>);
  }

  //<button onClick={ () => setShowCompanyPays(!showCompanyPays) }>Toggle Employer Contribution</button>

  const header = {
    'copay': "What will I pay for a doctor's appointment?",
    'nonCopay': "What will I pay for other medical costs?",
    'preventive': "What will I pay for preventive care?",
    'deductible': 'What is a deductible?',
    'mostYouCanPay': 'What is the most I can pay in a year?',
    'compare': 'Compare plans at various medical spends',
  }[selection] || 'Your available health insurance plans:';

  const explanation = {
    'copay': "A copay is a set amount you can pay for some doctor's visits instead of paying the full cost.",
    'nonCopay': "Examples? Like labs.",
    'preventive': "Preventive care, like vaccinations and [other examples here], is always fully covered.",
    'deductible': "A deductible is the amount you must pay in copays and medical costs before coinsurance kicks in.",
    'mostYouCanPay': "The most you can pay in a year is the sum of your premiums and out of pocket max.",
    'compare': "Enter an amount of medical spend and see what the cost to you would be.",
  }[selection];

  const questions = [
    {
      name: "Examples",
      options: [
        { title: 'What do I pay if I go to a doctor?', selection: 'copay' },
        { title: 'What do I pay for preventive medical care?', selection: 'preventive' },
        { title: 'What do I pay for other care?', selection: 'nonCopay' },
      ],
    },
    {
      name: "Medical Costs",
      options: [
        { title: 'What is the least I might pay in a year?', selection: 'leastYouCanPay', todo: true },
        { title: 'What is the most I can pay in a year?', selection: 'mostYouCanPay' },
        { title: 'What is an average amount I might pay in a year?', selection: 'averageYouCanPay', todo: true },
        { title: 'How can I compare the expected value of each plan? (This is the cool one!)', selection: 'compare' },
        { title: 'In which order would I hit the OOPM?', selection: 'oopmOrder', todo: true },
        { title: 'What are the break even points between plans?', selection: 'breakEven', todo: true },
      ],
    },
    {
      name: "Definitions",
      options: [
        { title: 'What is a deductible?', selection: 'deductible' },
        { title: 'What is an out of pocket max?', selection: 'oopm', todo: true },
        { title: 'What is coinsurance?', selection: 'coinsurance', todo: true },
        { title: 'What is a copay?', selection: 'copa2y', todo: true },
      ],
    },
    {
      name: "Other",
      options: [
        { title: 'Show the employer contribution', selection: null },
      ],
    },
  ];

  function renderMedicalSpendInput() {
    if (selection !== 'compare') {
      return null;
    }

    const exampleSpends = [
      { num: 0, text: "$0" },
      { num: 500, text: "$500" },
      { num: 1000, text: "$1,000" },
      { num: 2500, text: "$2,500" },
      { num: 5000, text: "$5,000" },
      { num: 10000, text: "$10,000" },
      { num: 25000, text: "$25,000" },
    ];

    return (<div className='medical-spend-input'>
      <label>Edit Medical Spend: <input type='numeric' value={ medicalSpend } onChange={ (e) => setMedicalSpend(e.target.value) } /></label>
      { exampleSpends.map( spend => <button onClick={ () => setMedicalSpend(spend.num) }>{ spend.text }</button> ) }
    </div>);
  }

  return (<div className='HealthInsuranceExplainer'>
    <h1>{ header }</h1>
    { explanation && <p>{ explanation }</p> }
    { renderMedicalSpendInput() }
    { plans.map(renderPlan) }
    { questions.map(q => <div>
      <h2>{ q.name }</h2>
      <ul>
        { q.options.map(o => <li className='question' onClick={() => setSelectionAndScroll(o.selection) } style={{ opacity: o.todo ? 0.4 : 1.0 }} >
          { o.title }
        </li>) }
      </ul>
    </div>) }
  </div>);
}

// TODO:
// click on any li, highlight it in the grid
// show:
//   before meeting your deductible: you pay copay, or total if no copay for that
//   after meeting deductible, before oopm: you pay coinsurance (or copay if present)
//   after oopm: you pay nothing
//
//  how to easily show difference between oopm (which is inclusive of deductible) vs premiums (not included?)
//  show payments into HSA from company? as negative charge, shifting next function left? chart should have ticks underneath
//  label shows how wide it is
//
//
//  todo: highlight things
