import './FlexboxFriend.css';

import { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import cubehelixColor from './cubehelix';

function cleanPx(v) {
  if (v === "" || v == null || isNaN(v)) {
    return null;
  } else {
    return `${v}px`;
  }
}


export default function FlexboxFriend() {
  const [width, setWidth] = useState(1200);
  const [baseColorId] = useState(() => Math.floor(Math.random() * COLOR_MODULUS));
  const [gutter, setGutter] = useState(20);
  const [showOptions, setShowOptions] = useState(true);

  function resize(event) {
    setWidth(Number(event.target.value));
  }

  return (<div className="FlexboxFriend">
    <input className='width-slider' type="range" min="200" max="1200" value={ width } onChange={ resize } />
    <div>
      <div className="Options__option">
        <label>Show Options</label>
        <input type='checkbox' checked={ showOptions } onChange={ (e) => setShowOptions(!!e.target.checked) } />
      </div>
      { showOptions && <div className="Options__option">
        <label>gutter width</label>
        <input type='number' value={ gutter } onChange={ (e) => setGutter(e.target.value) } />
        <button onClick={ () => setGutter("") }>✕</button>
      </div> }
    </div>
    <div className='resizable' style={{ maxWidth: width, }}>
      <Container
        starter={true}
        gutter={ gutter }
        showOptions={ showOptions }
        baseColorId={ baseColorId }
      />
    </div>
  </div>);
}


// okay, can click on anything, select it
// put UI in there? or portal it?
// or just do callbacks?

const COLOR_MODULUS = 24;

function Container({ starter, showOptions, gutter, baseColorId, baseName }) {
  baseName = baseName || "element";

  const [children, setChildren] = useState(starter ? [1] : []);

  const el = useRef(null);
  const latestWidth = useRef(null);

  function updateWidthText() {
    const width = el.current.clientWidth;
    if (latestWidth.current !== width) {
      latestWidth.current = width;
      const span = el.current.querySelector('.width-text');
      if (span) {
        span.innerHTML = width;
      }
    }
  }

  // on every re-render
  useEffect(updateWidthText);

  useEffect(() => {
    window.addEventListener('resize', updateWidthText);
    return () => {
      window.removeEventListener('resize', updateWidthText);
    }
  }, []);

  function removeChild(key) {
    setChildren(_.without(children, key))
    // Okay, this is a hack to get other divs to re-render
    // their size
    window.dispatchEvent(new Event('resize'));
  }

  function addChild() {
    setChildren([
      ...children,
      (children[children.length-1] || 0) + 1,
    ]);
  }

  return (<div className='Container' ref={ el }>
    { showOptions && <div className="Options">
      <button onClick={ addChild }>Add Child</button>
    </div> }
    <div className='Container__width_label'>
      width: <span className='width-text' />px
    </div>

    <div className='Container__actual_flex_container' style={{ gap: cleanPx(gutter) }}>
      { children.map(key => <Child
        key={key}
        showOptions={ showOptions }
        baseColorId={ baseColorId }
        baseName={ `${baseName}_${key}` }
        colorId={key}
        removeSelf={ () => removeChild(key) }
        gutter={ gutter }
      />) }
    </div>
  </div>);
}

function Child({ baseName, baseColorId, colorId, gutter, showOptions, removeSelf }) {
  const [text, setText] = useState(baseName);

  // TODO: debounce these
  const [minWidth, setMinWidth] = useState(250);
  const [maxWidth, setMaxWidth] = useState(null);
  const [fullWidth, setFullWidth] = useState(false);

  const [minHeight, setMinHeight] = useState(200);
  const [growRatio, setGrowRatio] = useState(1);

  const el = useRef(null);

  let css = {
    backgroundColor: cubehelixColor(((baseColorId + colorId) % COLOR_MODULUS) / COLOR_MODULUS),
    flexGrow: growRatio,
    minWidth: cleanPx(minWidth),
    maxWidth: cleanPx(maxWidth),
    minHeight: cleanPx(minHeight),
  };

  if (fullWidth) {
    css.width = '100%';
  }

  // useref for self
  function lock(field, setter) {
    return () => {
      setter(el.current[field]);
    };
  }

  return (<div className='Child' ref={ el } style={ css }>
    { showOptions ? <div className="Options">
      <input type='text' className='Child__name' value={ text } onChange={ (e) => setText(e.target.value) } placeholder="element name" />
      <button onClick={ removeSelf }>Remove</button>
      <div className="Options__option">
        <label>100% width</label>
        <input type='checkbox' checked={ fullWidth } onChange={ (e) => setFullWidth(!!e.target.checked) } />
      </div>
      <div className="Options__option">
        <label>min-width</label>
        <input type='number' value={ minWidth } onChange={ (e) => setMinWidth(e.target.value) } />
        <button onClick={ () => setMinWidth("") }>✕</button>
        <button onClick={ lock('clientWidth', setMinWidth) }>Lock</button>
      </div>
      <div className="Options__option">
        <label>max-width</label>
        <input type='number' value={ maxWidth } onChange={ (e) => setMaxWidth(e.target.value) } />
        <button onClick={ () => setMaxWidth("") }>✕</button>
        <button onClick={ lock('clientWidth', setMaxWidth) }>Lock</button>
      </div>
      <div className="Options__option">
        <label>min-height</label>
        <input type='number' value={ minHeight } onChange={ (e) => setMinHeight(e.target.value) } />
        <button onClick={ () => setMinHeight("") }>✕</button>
      </div>
      <div className="Options__option">
        <label>grow ratio</label>
        <input type='number' value={ growRatio } onChange={ (e) => setGrowRatio(e.target.value) } />
        <button onClick={ () => setGrowRatio(1) }>✕</button>
      </div>
    </div> : <div className="Child__text">{ text }</div> }
    <Container
      gutter={ gutter }
      showOptions={ showOptions }
      baseColorId={ baseColorId + colorId + 2 }
      baseName={ baseName }
    />
  </div>);
}
