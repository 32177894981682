import './Layout.css';

import { useState } from 'react';
import cubehelixColor from './cubehelix';

export default function Layout({ children }) {
  const [c, setC] = useState(Math.random());

  return (
    <div className='App'>
      <div className='container container--main'>
        <header className='container__col'
          onClick={() => setC((c) => (c + 0.1) % 1.0)}
          style={ { backgroundColor: cubehelixColor(c).toString() } }
        >
          <h1><span>Hi.</span><span className='easter-egg'>(P.S. you can click on this.)</span></h1>
        </header>
          { children }
        <footer className='container__col'>©{ (new Date()).getFullYear().toString() } me, Jesse Youngmann</footer>
      </div>
    </div>
  );
}
